import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache/cache.service';
import { Apartment, ApartmentRole, Fault, FaultCode, Scope, TenantUnit, TenantUnitRole, User } from '../../models';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  isLoading: boolean;
  isResettingCache: boolean;

  readonly currentYear: number;
  emailLoginForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private cache: CacheService,
    private renderer2: Renderer2
  ) {
    this.currentYear = new Date().getFullYear();
    this.isLoading = false;
    this.isResettingCache = false;
    this.emailLoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  ngOnInit() {
    this.renderer2.addClass(document.body, 'login-body');
  }

  ngOnDestroy() {
    this.renderer2.removeClass(document.body, 'login-body');
  }

  async onSubmit() {
    // localStorage.setItem('lastFetchedStories', new Date(0).toString());
    const f = this.emailLoginForm;
    if (!f.valid) {
      return;
    }

    const email: string = f.value.email;
    const password: string = f.value.password;

    this.isLoading = true;
    try {
      await this.cache.signInByEmail(email, password);
      //  await this.cache.userHasPolled();

      const activeUserId: string = this.cache.getActiveUserId();
      const activeUser = await this.cache.getUserFromApi(activeUserId);
      // sconst activeUser = await this.cache.getEntityFromApi(User.getUrl(activeUserId), User.getFactory());

      let isTenantUnitAdmin = false;
      let isContractOwner = false;

      const fetchedTenantUnits: Set<string> = new Set();
      const fetchedCompanies: Set<string> = new Set();

      // for (let i = 0; i < activeUser.roles.length; i++) {
      //   const r = activeUser.roles[i];
      //   // Admin role
      //   if (r instanceof TenantUnitRole && r.scopes >= Scope.TenantUnitContentAdmin) {
      //     isTenantUnitAdmin = true;
      //     if (!fetchedTenantUnits.has(r.tenantUnitId)) {
      //       // await this.cache.getTenantUnit(r.tenantUnitId);
      //       try {
      //         await this.cache.getEntityFromApi(TenantUnit.getUrl(r.tenantUnitId), TenantUnit.getFactory());
      //       } catch (e) {
      //         console.log(e);
      //       }
      //       fetchedTenantUnits.add(r.tenantUnitId);
      //     }
      //   }
      //   // Contract owner role
      //   else if (r instanceof ApartmentRole && r.scopes >= Scope.ApartmentContractOwner) {
      //     isContractOwner = true;
      //     if (!fetchedCompanies.has(r.apartmentId)) {
      //       // await this.cache.getApartment(r.tenantUnitId, r.apartmentId);
      //       try {
      //         await this.cache.getEntityFromApi(
      //           Apartment.getUrl(r.tenantUnitId, r.apartmentId),
      //           Apartment.getFactory()
      //         );
      //       } catch (e) {
      //         console.log(e);
      //       }
      //       fetchedCompanies.add(r.apartmentId);
      //     }
      //   }
      // }

      await this.router.navigate(['/tenant-unit/choose']);
      this.isLoading = false;
      return;
    } catch (x) {
      console.log('error logging in: ', x);
      if (x instanceof Fault) {
        switch (x.code) {
          case FaultCode.WrongPassword:
            f.controls.password.setErrors({ failure: 'Fel lösenord.' });
            break;
          case FaultCode.NotFound:
            f.controls.email.setErrors({ failure: 'Användaren är inte registrerad.' });
            break;
          default:
            f.controls.email.setErrors({ failure: x.text });
            break;
        }
      } else {
        f.controls.email.setErrors({ failure: x.text });
      }
    }

    this.isLoading = false;
  }

  async onResetCacheClick() {
    if (this.isLoading || this.isResettingCache) {
      return;
    }

    this.isResettingCache = true;

    await this.cache.resetCache();
    await this.cache.sleep(1500);

    window.location.reload();
  }

  get fc() {
    return this.emailLoginForm.controls;
  }

  get fv() {
    return this.emailLoginForm.value;
  }
}
