<!-- Black background -->
<div class="bg-wave-container">
  <img class="bg-waves" src="../../../assets/png/black-waves-10.png" alt="Srenity Background" />
</div>

<!-- Login -->
<div class="login-wrapper">
  <!-- Logo -->
  <div class="logo-area">
    <!-- <img
      class="logo-srenity"
      width="140"
      height="140"
      src="../../../assets/svg/serneke-login-logo.svg"
      alt="Srenity Logo"
    /> -->
    <h1>Voz Back Office</h1>
  </div>

  <!-- Form -->
  <form class="voz-form login-form" (ngSubmit)="onSubmit()" [formGroup]="emailLoginForm">
    <h2>Logga in med E-post</h2>

    <!-- Email  -->
    <div class="form-group">
      <div class="label-container">
        <label for="email">Email</label>
      </div>
      <input
        id="emailField"
        name="email"
        class="input-box"
        [class.input-error]="fc.email.touched && (fc.email.errors?.email || fc.email.errors?.failure)"
        placeholder="Ange email"
        type="string"
        formControlName="email"
      />
    </div>
    <!-- Password field -->
    <div class="form-group">
      <div class="label-container">
        <label for="password">Lösenord</label>
      </div>
      <input
        class="input-box"
        [class.input-error]="fc.password.touched && (fc.password.errors?.minLength || fc.password.errors?.failure)"
        placeholder="Ange lösenord"
        type="password"
        formControlName="password"
      />
    </div>

    <!-- Submit button -->
    <div class="btn-container">
      <button type="submit" class="btn btn-voz shadow-none" [class.btn-loading]="isLoading" [disabled]="isLoading">
        <span [hidden]="isLoading">Logga in</span>
      </button>
    </div>

    <!-- <a [routerLink]="['/login', 'bankid']">Logga in med BankID</a> -->
  </form>

  <!-- Footer -->
  <div class="footer-area">
    <span class="text-center" style="font-size: smaller"
      ><i>Voz Backoffice</i> &reg; {{ currentYear }} Voz Technologies AB</span
    >
  </div>
</div>

<!-- Clear cache -->
<!-- <div class="clear-cache-wrapper clickable" (click)="onResetCacheClick()">
  <div class="clear-cache-label-wrapper">
    <div [hidden]="isResettingCache" class="clear-cache-label">rensa cache</div>
    <div [hidden]="!isResettingCache" class="clear-cache-spinner"></div>
  </div>
</div> -->
